export const PicData =[
    {
        in_charge_type:'',
        incharge_name:'Guest House',
        emp_name:'Prof. S Shankar',
        emp_email:'ss@nitw.ac.in',
        emp_phone_no:' 9849102935',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ce-ss',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-ss',
    },
    // {
    //     in_charge_type:'',
    //     incharge_name:'Guest House',
    //     emp_name:'Prof. Sanjit Biswas',
    //     emp_email:'sbiswas@nitw.ac.in',
    //     emp_phone_no:'8130662889',
    //     emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ce-sbiswas',
    //     emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-sbiswas',
    // },
    {
        in_charge_type:'',
        incharge_name:'Security',
        emp_name:'Prof. Ravi Kumar Jatoth',
        emp_email:'ravikumar@nitw.ac.in',
        emp_phone_no:'8332969363',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ec-ravikumar',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-ravikumar',
    },
    {
        in_charge_type:'',
        incharge_name:'Electrical maintenance',
        emp_name:'Prof. B L Narasimharaju',
        emp_email:'blnraju@nitw.ac.in',
        emp_phone_no:'8332969294',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ee-blnraju',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ee-blnraju',
    },
    {
        in_charge_type:'',
        incharge_name:'CCPD',
        emp_name:'Prof. P Hari Krishna',
        emp_email:'ccpd_hod@nitw.ac.in',
        emp_phone_no:'94901 65357',
        emp_image:'https://nitw.ac.in/CIVDeptAssets/images/10.jpg',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-phari',
    },
    {
        in_charge_type:'',
        incharge_name:'Coodinator (Exam)',
        emp_name:'Prof. Kota Naga Srinivasarao Batta',
        emp_email:'srinu.bkn@nitw.ac.in',
        emp_phone_no:'9800296596',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ec-srinu.bkn',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-srinu.bkn',
    },
    {
        in_charge_type:'',
        incharge_name:'Coodinator Center of Excellence(Digital Man and Automation)',
        emp_name:'Prof. Adepu Kumar',
        emp_email:'adepu_kumar7@nitw.ac.in',
        emp_phone_no:'9492783067',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/me-adepu_kumar7',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-adepu_kumar7',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Media Relations/Public Relations Officer (PRO)',
        emp_name:'Prof. T. Rahul',
        emp_email:'rahult@nitw.ac.in',
        emp_phone_no:'8978264848',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/sm-rahult',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/sm-rahult',
    },
    {
        in_charge_type:'',
        incharge_name:'NSS Program officer cum Co-ordinator',
        emp_name:'Prof. G. Siva Kumar',
        emp_email:'gsivakumar@nitw.ac.in',
        emp_phone_no:'7702716890',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ee-gsivakumar',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ee-gsivakumar',
    },
    {
        in_charge_type:'',
        incharge_name:'NSS Program officer',
        emp_name:'Prof. K. Uday Kumar',
        emp_email:'kanapuram.udaykumar@nitw.ac.in',
        emp_phone_no:'9940107507',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage//ph-kanapuram.udaykumar',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ph-kanapuram.udaykumar',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Nodal Officer, Unnat Bharat Abhiyan ',
        emp_name:'Prof. M. Heeralal',
        emp_email:'mhl@nitw.ac.in ',
        emp_phone_no:'9052066114',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ce-mhl',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-mhl',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'NITW SIEMENS Centre of Excellence ',
        emp_name:'Prof. L. Krishnanand',
        emp_email:'siemenscoe_head@nitw.ac.in',
        emp_phone_no:'8332969311',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/me-lanka',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-lanka',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Air Conditioning',
        emp_name:'Prof. Prasanth Anand Kumar Lam',
        emp_email:'prasanth_anand@nitw.ac.in',
        emp_phone_no:'9840845194',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/me-prasanth_anand',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-prasanth_anand',
    },
    {
        in_charge_type:'',
        incharge_name:' NCC Officer ',
        emp_name:'Prof. Sudheer Kumar Yamsani',
        emp_email:'skyamsani@nitw.ac.in ',
        emp_phone_no:'8486367769',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ce-skyamsani',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-skyamsani',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Motor Transport Section ',
        emp_name:'Prof. T. Sadasiva Rao',
        emp_email:'sadasiv@nitw.ac.in',
        emp_phone_no:'9440035300',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/me-sadasiv',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/me-sadasiv',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Audio Visual Section, Seminars Halls, Old Auditorium and ALC',
        emp_name:'Prof. L. Anjaneyulu',
        emp_email:'anjan@nitw.ac.in',
        emp_phone_no:'8332969355',
        emp_image:'https://nitw.ac.in/ECEDeptAssets/images/f4.jpg',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-anjan',
    },
    {
        in_charge_type:'Prof In-charge',
        incharge_name:'Common Class Rooms and Facilities',
        emp_name:'Prof. B. Lakshmi',
        emp_email:'lakshmi@nitw.ac.in',
        emp_phone_no:'9493436845',
        emp_image:'https://erp.nitw.ac.in/ext/profile/getUserImage/ec-lakshmi',
        emp_page_link:'https://erp.nitw.ac.in/ext/profile/ec-lakshmi',
    },
    {
      in_charge_type:'Prof In-charge',
      incharge_name:'Student Counselling Service',
      emp_name:'Prof. Kamalini Devi',
      emp_email:'kamalinidevi@nitw.ac.in',
      emp_phone_no:'9337683749',
      emp_image:'https://nitw.ac.in/api/static/files/Kamalinidevi_2025-2-14-10-50-35.jpg',
      emp_page_link:'https://erp.nitw.ac.in/ext/profile/ce-Kamalinidevi',
  },
    // {
    //     in_charge_type:'',
    //     incharge_name:'',
    //     emp_name:'',
    //     emp_email:'',
    //     emp_phone_no:'',
    //     emp_image:'',
    //     emp_page_link:'',
    // },
]

export const IstemJsonData= [
    {
      sno: '1',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: '400MHz NMR',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/NMR_Spectrometer_2024-12-12-15-46-59.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33193/Nuclear-Magnetic-Resonance--NMR--400-MHz',
    },
    {
      sno: '2',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Powder XRD (Chemistry)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/xrd_form_2024-12-12-15-49-30.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40385/X-Ray-Diffraction--XRD-'
    },
    {
      sno: '3',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'LC HRMS',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/LCHRMS_2024-12-12-15-50-25.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33190/Liquid-Chromatography-High-Resolution-Mass-Spectrometer--LCHRMS-'
    },
    {
      sno: '4',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Universal Testing Machine (UTM)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/Universal_Testing_Machine_(UTM)_2024-12-12-15-51-33.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33199/Universal-Testing-Machine--UTM-'
    },
    {
      sno: '5',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'PCB Prototyping Design Facility (Engraving)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/PCB_Prototype_Design_Facility_2024-12-12-15-52-43.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40577/PCB-Prototype-Design-Facility',
    },
    {
      sno: '6',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'UV Vis NIR Spectrophotometer',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/UV_Vis_NIR_Spectrometer_2024-12-12-15-53-38.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33201/UV-VIS-NIR-Spectrophotometer',
    },
    {
      sno: '7',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Anechoic Chamber',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/Anechoic_chamber_2024-12-12-15-54-28.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/34723/Anechoic-Chamber',
    },
    {
      sno: '8',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Two Port Vector Network Analyzer',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/Vector_Network_Analyzer_2024-12-12-15-55-14.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/34722/Two-Port-Vector-Network-Analyzer',
    },
    {
      sno: '9',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Spectrum Analyzer',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/Spectrum_Analyzer_2024-12-12-15-56-2.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40401/Spectrum-Analyzer',
    },
    {
      sno: '10',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Powder XRD (MMED)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/xrd_form_MMED_2024-12-12-15-56-52.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33200/X-ray-Diffraction--XRD-',
    },
    {
      sno: '11',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'X-Band ESR Spectroscopy',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/ESR_Spectrometer_2024-12-12-15-57-45.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33191/X-band-Electron-Spin-Resonance--ESR--Spectroscopy',
    },
    {
      sno: '12',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'ICP OES',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/ICP-OES_2024-12-12-16-0-52.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33196/Inductively-Coupled-Plasma-Optical-Emission-Spectroscopy--ICPOES-',
    },
    {
      sno: '13',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'FTIR',
      requisition_form_link:' https://nitw.ac.in/api/static/files/FTIR_2024-12-12-16-1-47.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40525/Fourier-Transform-Infrared-Spectrometer--FTIR-',
    },
    {
      sno:'14',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'SEM (MMED)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/SEM_2024-12-12-16-2-37.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33198/Scanning-Electron-Microscope--SEM-',
    },
    {
      sno: '15',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'TG DTA',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/TGDTA_2024-12-12-16-3-50.pdf',
      istem_link:'https://www.istem.gov.in/equipment-info/33195/Thermogravimetric-Differential-Thermal-Analyzer--TGDTA-',
    },
    {
      sno: '16',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'DSC',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/DSC_2024-12-12-16-4-37.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/33194/Differential-Scanning-Calorimeter',
    },
    {
      sno: '17',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Wide Range Fluorescence Spectrophotometer',
      requisition_form_link:' https://nitw.ac.in/api/static/files/PL_2024-12-12-16-5-27.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40524/Fluorescence-Spectrophotometer',
    },
    {
      sno: '18',
      deptname:' Central Research Instrumentation Facility',
      eqp_name: 'CHNS(O) Analyzer',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/CHNS_Analyzer_2024-12-12-16-6-22.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/34724/CHNS-O--Analyzer',
    },
    {
      sno: '19',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'PCB Fabrication & Ethiching Facility',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/PCB_Fabrication_&_Etching_Facility_2024-12-12-16-7-11.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40578/PCB-Fabrication---Etching-Facility',
    },
    {
      sno: '20',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Vacuum Arc Remelting Furnace',
      requisition_form_link:' https://nitw.ac.in/api/static/files/Vacuum_Arc_Re-melting_Furnace_(VARF_2024-12-12-16-7-58.pdf',
      istem_link:' https://www.istem.gov.in/equipment-info/33207/Vacuum-Arc-Melting',
    },
    {
      sno: '21',
      deptname: 'Department of  Physics',
      eqp_name: 'FESEM',
    //   requisition_form_link: 'https://nitw.ac.in/api/static/files/fesem_2024-12-12-16-9-16.pdf',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/FESEM_2024-12-17-19-10-36.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/37704/Field-Emission-Scanning-Electron-Microscope--FESEM-',
    },
    {
      sno: '22',
      deptname:' Department of Chemistry',
      eqp_name: 'BET Surface Analyzer',
      requisition_form_link:' https://nitw.ac.in/api/static/files/BET_2024-12-12-16-10-10.pdf',
      istem_link:' https://www.istem.gov.in/equipment-info/40619/BET-Surface-Analyser',
    },
    {
      sno: '23',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: '3D Surface Profilometer',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/3D_Surface_Profilometer_2024-12-12-16-11-10.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/44312/3D-Surface-Profilometer',
    },
    {
      sno: '24',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Liquid Nitrogen Generator Plant (Chemistry)',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/liquid_nitrogen_2024-12-12-16-11-58.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/40552/Liquid-Nitrogen-Plant',
    },
    {
      sno: '25',
      deptname: 'Central Research Instrumentation Facility',
      eqp_name: 'Semi-Automatic Wire Bonder',
      requisition_form_link: 'https://nitw.ac.in/api/static/files/Semi-Automatic-Wire-Bonder_2025-4-2-11-9-58.pdf',
      istem_link: 'https://www.istem.gov.in/equipment-info/46181/Semi-Automatic-Wire-Bonder',
    },
]



// staff data
export const staffData = [
  { name: "Shri Sunil Kumar Mehta", designation: "Registrar", department: "" },
  // { name: "Shri T. Krishna Sai", designation: "Principal Scientific Officer", department: "Central Research Instrumentation Facility (CRIF)" },
  { name: "Dr. Yerramsetti Srinivasa Rao", designation: "Librarian", department: "Central Library" },
  { name: "Shri Venugopala Reddy Tamma", designation: "Superintending Engineer", department: "Estate Office" },
  { name: "Shri Soumen Karmakar", designation: "Dy. Registrar", department: "Establishment Section" },
  { name: "Dr. P. Prasanth", designation: "Senior SAS Officer", department: "Department of Physical Education" },
  { name: "Dr. Iranna Mallappa Shettar", designation: "Dy. Librarian", department: "Central Library" },
  { name: "Dr. Radha Rukmini Ganneni", designation: "Senior Medical Officer", department: "Institute Health Centre" },
  { name: "Shri Gaurav Chowdhury", designation: "Deputy Registrar", department: "Purchase & Stores" },
  { name: "Shri Veera Mani Kanth K", designation: "Dy. Registrar", department: "Finance & Accounts" },
  // { name: "Shri G. Ramesh", designation: "Executive Engineer", department: "Engineering & Maintenance Unit" },
  { name: "Shri P. Suresh", designation: "Executive Engineer", department: "Estate Office" },
  { name: "Shri P. Bhargava Reddy", designation: "Senior Technical Officer", department: "Centre for Digital Infrastructure and Services (CDIS)" },
  { name: "Shri Banothu Krishna Prasad", designation: "Senior Technical Officer", department: "Centre for Digital Infrastructure and Services (CDIS)" },
  { name: "Shri G. Balakrishna", designation: "Assistant Librarian", department: "Central Library" },
  { name: "Dr. D. Hari", designation: "SAS Officer", department: "Department of Physical Education" },
  { name: "Dr. Ch. Karthik", designation: "Medical Officer", department: "Institute Health Centre" },
  { name: "Dr. Pradeep Dodda", designation: "Medical Officer", department: "Institute Health Centre" },
  { name: "Dr. Anchoori Karthik", designation: "Medical Officer", department: "Institute Health Centre" },
  { name: "Smt. G Sree Rama Sanjeevini", designation: "Technical Officer", department: "Central Research Instrumentation Facility (CRIF)" },
  { name: "Shri D. Ravi Kumar", designation: "Technical Officer", department: "Central Research Instrumentation Facility (CRIF)" },
  { name: "Shri D. Govinda Rao", designation: "Technical Officer", department: "Centre for Digital Infrastructure and Services (CDIS)" },
  { name: "Shri Madupu Harish", designation: "Technical Officer", department: "Central Research Instrumentation Facility (CRIF)" },
  { name: "Shri V. Sudhakar", designation: "Technical Officer", department: "Central Research Instrumentation Facility (CRIF)" },
  { name: "Shri Adesh Srivastava", designation: "Assistant Registrar", department: "Sponsored Research and Industrial Consultancy (SRIC)" },
  { name: "Shri Nagaraju Nagabandi", designation: "Assistant Registrar", department: "Finance & Accounts" },
  { name: "Shri Ashok Kathuri", designation: "Assistant Registrar", department: "Finance & Accounts" },
  { name: "Shri Devkate Gajanan Vyankat", designation: "Assistant Registrar", department: "Director's Office" },
  { name: "Shri Peela Krishna Chaitanya", designation: "Assistant Registrar", department: "Establishment Section" },
  { name: "Shri Thummala Srinivas", designation: "Assistant Registrar", department: "Examiniation Section" },
  { name: "Shri Sudipta Manna", designation: "Technical Officer", department: "Centre for Digital Infrastructure and Services (CDIS)" },
  // { name: "Shri V. Upendar", designation: "Assistant Registrar (On deputation)", department: "Academic Cell" }
];