// const cardItems = [
//     // { 'title': "Dr. Kusum Kumari, Department of Physics is  selected for the INSA-Visiting Scientist Programme for the year 2023-24.", 'Content': '', 'url': 'https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16597' },
//     // { 'title': "CoCoLe 2023 has received financial assistance from SERB, DST, Govt. of India.", 'Content': '', 'url': 'https://ic-cocole.in/' },
//     // { 'title': "Director Prof. Bidyadhar Subudhi is nominated as Panel Member of ARMREB of DRDO", 'Content': '', 'url': '#' },
//     // { 'title': "Professor Venkatathri Narayanan of our Chemistry Department is elected as Fellow Member of the Royal Society of Chemistry, London", 'Content': '', 'url': '#' },
//     // { 'title': "Best Paper Award for PhD Student Nitish Gupta", 'Content': 'Nitish Gupta and D. Bhargavi, "An Analytical Study of the Impact of an Inclined Magnetic Field on Couette Flow with Jeffrey Fluid under Local Thermal Non-Equilibrium (LTNE) and Viscous Dissipation", paper presented at the "International Conference on Advancements in Materials, Manufacturing, and Automation (AMMA-2023)", held during April 7-8, 2023, organized by Amrita Vishwa Vidyapeetham, Tamil Nadu, India. ', 'url': '#' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },
//     // { 'title': "Title 1", 'Content': 'Content 1', 'url': 'https://www.nitw.ac.in/' },

// ]

// export default cardItems;

const cardItems = [
  // {
  //   title: '',
  //   Content: '',
  //   url: 'https://www.nitw.ac.in/'

  // },
    {
    title: 'Prof. T. Vinay Kumar (EE) received a project from ANRF entitled "Development of Advanced Speed Control Techniques for Five-Phase Permanent Magnet-Assisted Synchronous Reluctance Motor (PMa-SynRM)-Powered Electric Vehicles to Reduce Torque Ripples, Ensuring Smoother Operation and Enhanced Efficiency,"  under the IRG scheme of the Anusandhan National Research Foundation (ANRF).',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
    {
    title: 'Prof. Sangharatna Godboley (CS) received a project from ANRF entitled "Smart Constitution: Designing, Development, and Testing of Indian Penal Code 1860 through Blockchain based Smart Contract" under IRG scheme of the Anusandhan National Research Foundation (ANRF). ',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  


  {
    title: 'Prof. Sourabh Roy  and Prof V Jayalaksmi received an ISRO Respond project entitled " Cryo Temperature sensing by Fiber optic Sensors"',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },


  {
    title: 'Prof. Sangharatna Godboley and Prof. P. Radha Krishna received a project titled "TrustInn: Umbrella Industry Tool for Validating Blockchain Systems", which has been sanctioned with funding of Rs. 1.59 Crore by the IIT Bhilai Innovation and Technology Foundation (IBITF) under the Dedicated Innovation Accelerator (DIAL) scheme, DST, Government of India.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },


  {
    title: 'Prof. Bala prakash Rao Killi received project fromTechnology Innovation Hub, IBITF, IIT Bhilai under  EIR/PRAYAS Scheme 2025.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },

  {
    title: 'Prof. Prakash Saudagar received IRG grant from the Anusandhan National Research Foundation (ANRF).',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  
  {
    title: 'Prof. Priyanka Chawla received the Prime Minister Early Career Research Grant for the Project entitled"Design and development of lightweight, cost-effective and energy-aware Fog-based crop yield recommendation, disease diagnosis and management system using Artificial Intelligence techniques for rural India" from the Anusandhan National Research Foundation (ANRF).',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Vijay Choyal received the Prime Minister Early Career Research Grant for the Project entitled "Computational and Machine Learning-Assisted discovery of high-performance cathodes for Multivalent batteries" from the Anusandhan National Research Foundation (ANRF).',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Jew Das (CE) received the Prime Minister Early Career Research Grant for the Project entitled "Land-Atmosphere Coupling and their Impact on Compound Extremes" from the Anusandhan National Research Foundation (ANRF).',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. R. Padmavathy (CS) received a grant for the project entitled "Design and Development of Binary Navigation System" from ISRO',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Path Rakshak (Guardian of the Road): A System for Enhancing Public Transport Safety with Real-time Ambiance and Driver Monitoring, has been successfully sanctioned to Prof Muralidhar (EC)  from IIT Mandi iHub & HCI Foundation',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Solar cotton Harvest project has been granted to Prof. M. Heeralal and Prof. V. Mani under the Unnat Bharat Abhiyan (UBA) scheme.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Coal gangu granite dust based geopolymer  rehabilitation for rural roads has been granted to  Prof.M.Heeralal and  Prof.G.V.Ramana under Unnat Bharat Abhiyan (UBA) scheme.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. V Vasu (ME) has received the funding for  R&D project entitled "Synergizing AR/VR and Hand Gestures to Enhance Industrial Assembly and Disassembly Performance in Digital Manufacturing" as part of the ASEAN India international partnership initiative.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof Jew Das (CE) has been chosen for the esteemed Water Advanced Research and Innovation (WARI) Fellowship Program, which is sponsored by the Indo-US Science and Technology Forum (IUSSTF), the Daugherty Water for Food Institute (DWFI), the University of Nebraska-Lincoln (UNL), and the Department of Science and Technology, Government of India.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: ' Prof. Venkata Reddy and Prof. Litan Kumar Ray have been awarded a project proposal titled " Formulation of Sustainable Adaptation Strategies for Mitigation of Climate Impacts on Vulnerable Groundwater Systems of Arid and Semi-Arid Regions" with a total budget of Rs. 50,05,132/- for a three-year period under "Research & Development in Water Sector and Implementation of National Water Mission"',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Ramya Araga (CH) has received funding from the Indian Ministry of Textiles National Technical Textile Mission (NTTM) for a study involving stearic acid blends in polystyrene electrospun nanofibrous membranes.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof K. Anil Naik (EE), received funding from the Department of Scientific and Industrial Research -Ministry of Science and Technology for the proposal titled "Technology foresight study on integrated strategies for PV solar Power System."',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof  S. Anitha Priyadharshani (CE)  have received approval for a research project "Braided Fiber reinforced polymeric rebars for concrete structures" as the Principal Investigator (PI) worth 50.93 lakhs from the National Technical Textiles Mission, Ministry of Textiles along with Prof S. B. Singh (Co-PI) from BITS Pilani.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'The National Commission for Women, awarded research project entitled "Women in Unorganized Sectors," under Southern Zone 1 with financial assistance of Rs.27,83,000 to Prof. V.Rama Devi, Dept. of Management Studies as Principal Investigator and Prof.H.P.Rani, Dept. of Mathematics as Co-Investigator.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Congratulations to Prof P Muralidhar (EC) for the selection of research proposal under Ihub IIT Mandi',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Wire Arc Additive Manufacturing of Compositionally Graded Inconel 625-Copper Alloy Bimetallic Components for Aerospace Applications has been recommended for funding. The project is led by Dr. Kishore Babu Nagumothu, Principal Investigator (MME Department), with Dr. Manjaiah (MED, NITW) as the Co-Principal Investigator. The total project cost is ₹98 lakhs',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'The ISRO-RESPOND project "RES-MCF-2023-004: Development of Motion Controller for Full Motion Antenna" has been granted to Dr. R. Jeyasenthil (PI). The project is for three years, with a cost of Rs. 22,20,400/-.Congratulations to Prof Jeyasenthil EE for the award of the ISRO project',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Rakesh Kumar and  Prof. K. Uday Kumar have received a Project on  Development of high-performance triboelectric nanogenerator based on 2D MoS2@Metal organic framework Hybrid films for self-powered IoT systems',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },

  {
    title: 'Congratulations to Prof. Rathish Kumar Pancharathi , Dept. of Civil Engineering has been selected as the Senior Member, INAE. Prof Rathish Kumar Pancharathi has been also successful in securing two R&D project grants: (i) SPARC,  UKIERI, MoE project on Inspiring the researchers of tomorrow in sustainable concrete construction (ii) Project  on “Development of Geopolymer Aggregates from Ash(Dry and Pond) for applications in Bio-Bricks, Low grade Pavements and Precast Elements” Damodar Valley Corporation, GoI, Ministry of Power',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Congratulations to Prof Soumya Lipsa Rath (BT) for International project from National Institutes of Health (NIH), USA for simulation work',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Congratulations to Prof. Raghu Chitta and Prof. PV Srilakshmi, Prof. Gopi  Ram, Prof. Manjaiah M and Prof. Kishore Babu, Prof. Surajbhan  Sevda, Prof. M. Jerold, Prof. A. Kirubakaran and Prof. V. T. Somasekhar, Prof. Rathish for SPARC Project Grants.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Prof. Palash Mishra has received a Project on Surface Charge Mitigation in Nano-Micro Hybrid Silicone Rubber Composites for HVDC Insulation in Harsh Environmental Conditions',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Dr.V.Rama Devi, School of Management  has received a Project on"Glass Ceiling for Women in Corporate Sector- Antecedents, Outcomes & Policy Measures" 2023 sanctioned by National Commission for Women, New Delhi.',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'An Indian patent has granted to P. Abdul Azeem, P Srinath, K. Venugopal Reddy,  S. Rajkumar,  M Bamanandam, M Krishnam Raju, Sushil Patel "CALCIUM SILICATE BASED NANO CRYSTALLINE CERAMICS: A COST-EFFECTIVE METHOD FOR THE PREPARATION USING NATURAL RESOURCES" Grant No. 376586   Application No. 2020201941036738',
    Content: '',
    url: 'https://www.nitw.ac.in/'

  },
  {
    title: 'Thangaraju, Paul Joseph and V. Jayalakshmi, Department of Physics, have received a SPARC project on Hybrid Tetrapod Nanomaterials Based Sustainable Optoelectronic Technologies.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'Prof. Adepu Kumar, Department of Mechanical Engineering is granted with a patent titled System and Method for reinforcing powder particles using Friction Stir Processing along with his doctoral student Shri Naresh P.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'A.Suchith Reddy and Prof P.Rathish Kumar, Indian Patent Filed on A method and system for assessing sustainability of civil infrastructure, Application No. 202141054686, 26th November 2021.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'Mukul Pradhan, S. Rudra, R. Chakraborty File No. 202141054738 Synthesis of Graphitized Carbon from Biomass using Focused Sunlight in a Newly Designed Reaction Setup for Energy Storage Application, 2021.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'E. Suresh Babu An Efficient Spectral Cluster Routing Method to Enhance the Network Performance in Wireless Sensor Networks Publication The Patent Office Journal No. 12/2021 Dated 19/03/2021.',
    Content: ' ',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'Dr. V. Rama and Prof. C. B. Rama Rao have been granted a patent on A Tele -Health Care System and Method for Early Disease Detection bearing patent no. 2021105599, date: 03/11/2021.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  },
  {
    title: 'Dr. Suresh Babu Perli received Innovation patent from Australia for the title A Web-Enabled Smart Home Gateway for Automatic Device and Network Configuration and Automatic System Updates Via IoT, Date of Grant: 15 Dec 2021.',
    Content: '',
    url: 'https://www.nitw.ac.in/'
  }

  // {
  //   title: 'Jitesh Pani, Devkaran Maru, Priyanka Chaudhary, Jitendra Gangwar, Kanapuram Uday Kumar, Bal Chandra Yadav, and Hitesh Borkar, "Improved Supercapacitor Performance with Enhanced Interlayer Spacing of Nanoflower MoS2 in Long Discharge Time in LED-Glowing Application", 2023.',
  //   Content: '',
  //   url: 'https://doi.org/10.1002/ente.202300193'
  // }
];

export default cardItems;