import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deanrandc.css';

// export const DeanAcademic = () => {
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [activeTab, setActiveTab] = useState('deans');

//   const deans = [
//     {
//         name: "Dean (Academic)",
//         designation: " Dr. A. Sarath Babu",
//         phoneNumber: " 9490165361",
//         email: " dean_acad@nitw.ac.in",
//         image: "https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg",
//         link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16321",
//       },
//     // ... other deans
//   ];

//   const associateDeans = [
//     {
//         name: "Associate Dean (Academic Research Programs)",
//         designation: " Dr. V. Rajesh Khana Raju",
//         phoneNumber: " 9492416897",
//         email: " ad_acad.phd@nitw.ac.in",
//         image: "https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg",
//         link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16283",
//       },

//       {
//         name: "Associate Dean (Academic - PG)",
//         designation: " Prof. K. Thangaraju",
//         phoneNumber: " 8332969476",
//         email: " ad_acad.pg@nitw.ac.in",
//         image: "https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg",
//         link: "https://erp.nitw.ac.in/ext/profile/ph-ktr",
//       },

//       {
//         name: "Associate Dean (Academic - UG)",
//         designation: " Dr. Vidyasagar Shilapuram",
//         phoneNumber: " 8332969710",
//         email: " ad_acad.ug@nitw.ac.in",
//         image: "https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg",
//         link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16461",
//       },
//       {
//         name: "Associate Dean (Admissions)",
//         designation: " Prof. Venkaiah Chowdary",
//         phoneNumber: " 8332969703",
//         email: " admissions@nitw.ac.in",
//         image: "https://wsdc.nitw.ac.in/facultynew/media/16215/16215.jpg",
//         link: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16215",
//       },

//     // ... other associate deans
//   ];

//   const officeStaff = [
//     // {
//     //   name: 'Office Staff 1',
//     //   designation: " Name of the office staff",
//     //   phoneNumber: " 0123456789",
//     //   email: "research-dean@example.com",
//     //   image: 'http://localhost:3000/images/logo-170x172.png',
//     //   link: 'https://www.nitw.ac.in/',
//     // },
//     // ... other office staff
//   ];

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//     setHoveredIndex(null);
//   };

//   const handleHover = (index) => {
//     // setHoveredIndex(index);
//   };

//   const handleHoverEnd = () => {
//     // setHoveredIndex(null);
//   };

//   const activeData =
//     activeTab === 'deans'
//       ? deans
//       : activeTab === 'associateDeans'
//       ? associateDeans
//       : officeStaff;
//   const facilitiesPerColumn = Math.ceil(activeData.length / 2);

//   return (
//     <div className="page text-center font-change-applicable static-facilities">
//       <PageHeader dept="/Dean Academic" />
//       <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
//         <div className="tabs">
//           <button
//             className={`tab ${activeTab === 'deans' ? 'active' : ''}`}
//             onClick={() => handleTabClick('deans')}
//           >
//             Deans
//           </button>
//           <button
//             className={`tab ${activeTab === 'associateDeans' ? 'active' : ''}`}
//             onClick={() => handleTabClick('associateDeans')}
//           >
//             Associate Deans
//           </button>
//           <button
//             className={`tab ${activeTab === 'officeStaff' ? 'active' : ''}`}
//             onClick={() => handleTabClick('officeStaff')}
//           >
//             Office Staff
//             </button>
//         </div>
//         <div className="columns-wrapper row">
//           {activeData.map((person, index) => (
//             <div className={`column col-md-6 mb-6  ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
//               <div
//                 className="custom-circle-image"
//                 onMouseEnter={() => handleHover(index)}
//                 onMouseLeave={handleHoverEnd}
//               >

//                 <a href={person.link} target="_blank" rel="noopener noreferrer">
//                   <img src={person.image} alt={person.name} />
//                 </a>

//               </div>
//               <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
//                 <h2>{person.name}</h2>
//                 <div className="details-wrapper">
//                   <div className="details-row">
//                     <span className="details-label">Name:</span>
//                     <span className="details-value">{person.designation}</span>
//                   </div>

//                   <div className="details-row">
//                     <span className="details-label">Phone:</span>
//                     <span className="details-value">{person.phoneNumber}</span>
//                   </div>
//                   <div className="details-row">
//                     <span className="details-label">Email:</span>
//                     <span className="details-value">{person.email}</span>
//                   </div>

//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DeanAcademic;


export const DeanAcademic = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('deans');

  const deans = [
    {
      name: "Prof. A. Sarath Babu",
      designation: "Dean (Academic)",
      phoneNumber: " 9490165361",
      email: " dean_acad@nitw.ac.in",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ch-sarat",
    },
    // ... other deans
  ];

  const associateDeans = [
    // {
    //   name: " Dr. V. Rajesh Khana Raju",
    //   designation: "Associate Dean (Academic Research Programs)",
    //   phoneNumber: " 9492416897",
    //   email: " ad_acad.phd@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/me-vrkraju",
    // },

    // {
    //   name: "Prof. K. Thangaraju",
    //   designation: "Associate Dean (Academic - PG)",
    //   phoneNumber: " 8332969476",
    //   email: " ad_acad.pg@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ph-ktr",
    // },

    // {
    //   name: "Dr. Vidyasagar Shilapuram",
    //   designation: "Associate Dean (Academic - UG)",
    //   phoneNumber: " 8332969710",
    //   email: " ad_acad.ug@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/ch-vidyasagars",
    // },
    {
      name: "Prof. K V R Ravi Shankar",
      designation: "Associate Dean (Academic Research Programs)",
      // phoneNumber: "9492416897",
      phoneNumber: "8332969262",
      email: "ad_acad.phd@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ce-ravikvr",
      link: "https://erp.nitw.ac.in/ext/profile/ce-ravikvr",
    },

    {
      name: "Prof. D. Bhargavi",
      designation: "Associate Dean (Academic - PG)",
      // phoneNumber: "8332969262",
      phoneNumber: "8332969456",
      email: "ad_acad.pg@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ma-bhargavi",
      link: "https://erp.nitw.ac.in/ext/profile/ma-bhargavi",
    },

    {
      name: "Prof. Syed Ismail",
      designation: "Associate Dean (Academic - UG)",
      // phoneNumber: "8332969710",
      phoneNumber: "8332969367",
      email: "ad_acad.ug@nitw.ac.in",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-syedismail7",
      link: "https://erp.nitw.ac.in/ext/profile/me-syedismail7",
    },




    {
      name: " Prof. S. Ravi Chandra",
      designation: "Associate Dean (Admissions)",
      phoneNumber: " 8332969703",
      email: " ad_acad.ug@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Prof._S._Ravi_Chandra_2024-3-4-18-30-42.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cs-ravic",
    },

    // ... other associate deans
  ];

  const officeStaff = [
    // ... office staff data


    // {
    //   name: "Shri. V. Upendar",
    //   designation: "Assistant Registrar",
    //   phoneNumber: " 9182395010",
    //   email: " ar_acad@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/upendra_2024-12-2-18-56-43.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },
    // {
    //   name: "Shri. T. Sreenivas",
    //   designation: "Assistant Registrar",
    //   phoneNumber: " 8332969563",
    //   email: " ar_acad@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/T._Sreenivas_2024-3-7-12-38-21.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },
    {
      name: "Shri. R. Gopinath",
      designation: "Superintendent (SG-II)",
      phoneNumber: " 8332669545",
      email: " acad_supdt@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Shri._R._Gopinath_2023-11-3-10-52-3.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },



    // {
    //   name: " S. Surya Prakash",
    //   designation: "Superintendent",
    //   phoneNumber: " 9177462682",
    //   email: " acad_supdt@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/S._Surya_Prakash_2024-3-7-12-39-25.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },



    // {
    //   name: "Shri. Md. Afzal",
    //   designation: "Asst. SG-I",
    //   phoneNumber: " 8332969546",
    //   email: "acad_pgseat@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Md._Afzal_2024-3-7-12-40-50.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },



    {
      name: "Shri. Y. Mahesh Kumar",
      designation: "Asst. SG-II",
      phoneNumber: " 8332969538",
      email: "acad_phdseat@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Y._Mahesh_Kumar_2024-3-7-12-42-12.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },
    {
      name: "Dr. Nanu Lunavath",
      designation: "Asst. SG-II",
      phoneNumber: " 8332969738",
      email: "acad_nspscholarships@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/nanu_2024-12-2-18-56-6.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },

    {
      name: " Dr. B. Mangilal ",
      designation: "Sr. Asst.",
      phoneNumber: " 8332969691",
      email: "acad_ugseat@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Dr._B._Mangilal_2024-3-7-12-44-11.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },
    // {
    //   name: "Shri. Jakkula Srinivas",
    //   designation: "Sr. Asst.",
    //   phoneNumber: " 0870-2462059",
    //   email: " jakkulasrinivas@nitw.ac.in",
    //   image: "https://nitw.ac.in/CIVDeptAssets/images/st3.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },


    {
      name: "Shri. Md. Shoukat Ali  ",
      designation: "Sr. Asst.",
      phoneNumber: " 8332969577",
      email: "acad_foreignseat@nitw.ac.in",
      image: "https://nitw.ac.in/api/static/files/Md._Shoukat_Ali_2024-3-7-12-45-15.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },
    {
      name: "Shri A. Jagadeesh Kumar ",
      designation: "Sr. Asst.",
      phoneNumber: " 8332969564",
      email: "",
      image: "https://nitw.ac.in/api/static/files/Shri_A._Jagadeesh_Kumar_2023-10-6-12-52-11.jpg",
      link: "https://www.nitw.ac.in/DeanAcademic",
    },


    // {
    //   name: "Shri. A. Jai Singh  ",
    //   designation: "Assistant(SG-I)",
    //   phoneNumber: " 8332969445",
    //   email: "",
    //   image: "https://nitw.ac.in/api/static/files/Mr.A.Jai_Singh_2023-9-20-13-18-3.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },
    // {
    //   name: " Md. Jafar  ",
    //   designation: "Jr. Asst.",
    //   phoneNumber: " 8332969524",
    //   email: "acad_statescholarships@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Md._Jafar_2024-3-7-12-46-41.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },


    // {
    //   name: "Shri. Sardare Abhijeet Pralhad",
    //   designation: "Jr. Asst.",
    //   phoneNumber: " 8605566798 ",
    //   email: "acad_nspscholarships@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Mr._Sardare_Abhijeet_Pralhad_2024-3-7-12-47-40.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },
    // {
    //   name: "Shri. M. Bhaskar",
    //   designation: "Lab Attendant",
    //   phoneNumber: " 9441271182 ",
    //   email: "mysabhaskar@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Bhaskar_photo_2024-6-19-11-1-53.jpg",
    //   link: "https://www.nitw.ac.in/DeanAcademic",
    // },


  ];


  const academicCellActivities = [
    // Academic cell activities data
  ];



  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    setHoveredIndex(null);
  };

  const activeData =
    activeTab === 'deans'
      ? deans
      : activeTab === 'associateDeans'
        ? associateDeans
        : activeTab === 'officeStaff'
          ? officeStaff
          : academicCellActivities;

  const facilitiesPerColumn = Math.ceil(activeData.length / 2);

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/DeanAcademic" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'deans' ? 'active' : ''}`}
            onClick={() => handleTabClick('deans')}
          >
            Dean
          </button>
          <button
            className={`tab ${activeTab === 'associateDeans' ? 'active' : ''}`}
            onClick={() => handleTabClick('associateDeans')}
          >
            Associate Deans
          </button>
          <button
            className={`tab ${activeTab === 'officeStaff' ? 'active' : ''}`}
            onClick={() => handleTabClick('officeStaff')}
          >
            Office Staff
          </button>
          <button
            className={`tab ${activeTab === 'academicCellActivities' ? 'active' : ''}`}
            onClick={() => handleTabClick('academicCellActivities')}
          >
            Academic Cell Activities
          </button>
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
                <h2>{person.designation}</h2>
                <div className="details-wrapper">
                  {/* {person.designation && (
                    <div className="details-row">
                      <span className="details-label">designation:</span>
                      <span className="details-value">{person.designation}</span>
                    </div>
                  )} */}
                  {person.name && (
                    <div className="details-row">
                      <span className="details-label">Name: </span>
                      <span className="details-value">{person.name}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone: </span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email: </span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.person1 && (
                    <div className="details-row">
                      <span className="details-label"> </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}

                </div>
              </div>
            </div>
          ))}


          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'academicCellActivities' && (
            <div className="computer-centre-section">
              {/* <h4>description:</h4> */}
              <h4 style={{ textAlign: 'left' }}>Associate Dean Responsibilities
              </h4>


              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th style={{ fontSize: '18px' }}>Associate Deans</th>
                    <th style={{ fontSize: '18px' }}>	Roles and Responsibilities</th>

                  </tr>
                  <tr>
                    <td>Associate Dean(Academic-UG)<br></br>
                      Phone: +91-870-2462059<br></br>
                      Email: ad_acad.ug@nitw.ac.in</td>
                    <td>1. Course registration<br></br>
                      2. Time Tables<br></br>
                      3. Curriculum Development<br></br>
                      4. Board of Studies<br></br>
                      5. NBA and NIRF data of UG<br></br>
                      6. Branch change</td>

                  </tr>


                  <tr>
                    <td>Associate Dean(Academic - PG)<br></br>
                      Phone: +91-870-2462985 <br></br>
                      Email: ad_acad.pg@nitw.ac.in</td>
                    <td>1. Course registration<br></br>
                      2. Curriculum Development Board of Studies<br></br>
                      3. Preparation of Plagiarism report<br></br>
                      4. NBA and NIRF data of PG <br></br>
                    </td>

                  </tr>


                  <tr>
                    <td>Associate Dean(Academic - Research)<br></br>
                      Phone: +91-870-2462055 <br></br>
                      Email:  ad_acad.phd@nitw.ac.in </td>
                    <td>1. Course registration<br></br>
                      2. Curriculum Development Board of Studies<br></br>
                      3. Preparation of Plagiarism report<br></br>
                      4. NBA and NIRF data of Ph.D <br></br>
                    </td>

                  </tr>


                  <tr>
                    <td>Professor-In-Charge(Examinations)<br></br>
                      Phone: +91-870-2462021, 22, 25  <br></br>
                      Email: ad_exam@nitw.ac.in </td>
                    <td>1. Examination schedules Results and Grades<br></br>
                      2. Co-ordination for the conduct of Institute day / Convocation<br></br>
                      3. Data for Convocation / Institute day / Annual report<br></br>
                      4. Issue of semester grade<br></br>
                      sheets and consolidated grade sheet<br></br>
                      5. Issue of Original degree certificates.<br></br>
                      6. Transcripts<br></br>
                      7 Academic Genuineness
                    </td>

                  </tr>


                  <tr>
                    <td>Associate Dean(Admissions)<br></br>
                      Phone: +91-870-2462060<br></br>
                      Email: nitwadm@gmail.com  </td>
                    <td><br></br>Admissions of all the UG, PG Ph.D programmes and PDF Position.
                    </td>

                  </tr>

                </tbody>
              </table>

              <br></br>
              <h4 style={{ textAlign: 'left' }}>Academic Staff Responsibilities
              </h4>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th style={{ fontSize: '18px' }}>Academic Staff</th>
                    <th style={{ fontSize: '18px' }}>	Roles and Responsibilities</th>

                  </tr>
                  <tr>
                    <td>
                      <b>Asst. Registrar (Academic)</b><br></br>
                      Phone: +91-870-2462055<br></br>
                      Email: dr_acad@nitw.ac.in
                    </td>
                    <td>
                      1. Coordination with the staff of Academic section and Examination section.<br></br>
                      2. Leave permissions to the staff in academic section<br></br>
                      3. General day to day activities in the Academic section<br></br>
                      4. Coordinating and looking after the following activities:<br></br>
                      1. Scholarships for B.Tech.<br></br>
                      2. ICCR<br></br>
                      3. SPDC<br></br>
                      4. Study In India (SII)<br></br>
                      5. Procurement of infrastructure or any other items<br></br>
                      6. Annual Report Data<br></br>
                      7. Data requested by MHRD during parliament sessions<br></br>
                      8. Bonafide Certificate and NOC for existing students<br></br>
                      9. Study & Conduct, TC & Migration Certificates<br></br>
                      10. Character and Bonafide certificates<br></br>
                      11. Utilization certificate<br></br>
                      12. Income certificate<br></br>
                      13. No dues<br></br>
                      14. RTI and MHRD queries<br></br>
                      15. Senate matters<br></br>
                      16. M.Tech. Stipends<br></br>
                      17. Ph.D fellowships (UGC, Visweswaraya, QIP, Institute)

                    </td>

                  </tr>


                  <tr>
                    <td>
                      <b>Superintendent</b><br></br>
                      Phone: +91-870-2462063 <br></br>
                      Email: acad_supdt@nitw.ac.in
                    </td>



                    <td>
                      <br></br>
                      1. Coordinating with all the seats in Academic section

                    </td>

                  </tr>



                  <tr>
                    <td>
                      <b>UG seat</b><br></br>
                      Phone: +91-870-2462056<br></br>
                      Email: acad_ugseat@nitw.ac.in
                    </td>



                    <td>

                      1. Temporary Break of Study of UG<br></br>
                      2. UG Admission Cancellation<br></br>
                      3. Income certificate<br></br>
                      4. Branch change<br></br>
                      5. No Objection Certificate, Study & Conduct certificate,  Transfer and Migration Certificate, Custodian Certifcate, No dues certificate<br></br>
                      6. Maintenance of Academic Records, Archiving of Academic Records<br></br>
                      7. NBA and NIRF data for UG<br></br>
                      8. Information for RTI / MHRD queries

                    </td>

                  </tr>

                  <tr>
                    <td>
                      <b>PG Seat</b><br></br>
                      Phone: +91-870-2462057 <br></br>
                      Email: acad_pgseat@nitw.ac.in
                    </td>



                    <td>

                      1. M.Tech. Stipends<br></br>
                      2. No Objection Certificate, Study & Conduct certificate, Transfer and Migration Certificate, Custodian Certifcate
                      , No dues certificate<br></br>
                      3. Temporary Break of Study of PG<br></br>
                      4. Full-time to part-time conversion<br></br>
                      5. PG Admission Cancellation<br></br>
                      6. Maintenance of Academic Records, Archiving of Academic Records<br></br>
                      7. Information for RTI / MHRD queries<br></br>
                      8. NBA and NIRF data of PG

                    </td>

                  </tr>


                  <tr>
                    <td>
                      <b>Ph.D Seat</b><br></br>
                      Phone: +91-870-2462062<br></br>
                      Email: acad_phdseat@nitw.ac.in
                    </td>

                    <td>
                      1. Ph.D fellowships (UGC, Visweswaraya, QIP, Institute)<br></br>
                      2. Extension and Enhancement of Fellowships<br></br>
                      3. Orders of Maternity and Paternity leaves<br></br>
                      4. Permission for external Research work<br></br>
                      5. Full time to Part time conversion JRF project to Institute fellowship conversion<br></br>
                      6. Temporary Break of Study of Ph.D<br></br>
                      7. Ph.D Admission Cancellation<br></br>
                      8. Provisional, Transfer cum Migration certificates<br></br>
                      9. Bonafide certificateData for Convocation / Institute day / Annual report<br></br>
                      10. Maintenance of Academic Records, Archiving of Academic Records<br></br>
                      11. Information for RTI / MHRD Queries, NAD

                    </td>

                  </tr>

                  <tr>
                    <td>
                      <b>NSP, Institute Merit / Means Scholarships
                        / UGC Fellowships & & PDF <br></br>
                        (for the Concern of admission only)</b><br></br>
                      Phone: +91-870-2462058 <br></br>
                      Email: acad_nspscholarships@nitw.ac.in
                    </td>



                    <td>

                      <b>  NSP Scholarships</b><br></br>
                      1. Providing the bonafide certificate<br></br>
                      2. Collecting the NSP Scholarship soft and hard copies.<br></br>
                      3. Forwarding the online scholarship application from the Institute login as per the eligibility.<br></br><br></br>

                      <b>Institute Merit Scholarships Scheme is replaced with the Institute Merit Cum Means Scholarship Scheme from the Academic year 2021-22.</b><br></br>
                      1. Every year Institute gives merit Scholarship<br></br>
                      2. Whose family Annual Income is less than five lakhs are only eligible for this Scholarship Scheme.<br></br>
                      3. Reimbursement of one year’ s paid tuition fee with a merit Scholarship certificate based on their previous year performance to the UG students only.<br></br><br></br>

                      <b>Post-Doctoral Fellowship:</b><br></br>
                      1. Forwarding the applications to the concern department.<br></br>
                      2. Consolidation of the shortlisted candidates for the Interviews.<br></br>
                      3. Documental verification at time of Interview.<br></br>
                      4. Submission of the selected candidates documents to the Administrations for further procedure. <br></br>

                    </td>

                  </tr>


                  <tr>
                    <td>
                      <b>Other State Scholarships </b><br></br>
                      Phone:+91-8702462985  <br></br>
                      Email: acad_statescholarships@nitw.ac.in
                    </td>



                    <td>

                      <b>  All State Post Matric Scholarship </b><br></br>
                      1. The State Scholarships, which are not covered under NSP. <br></br>
                      2. Providing the bonafide certificates <br></br>
                      3. Student submit the soft and hard copies to the Institute, the applications will be forwarded through online / manually to the concerned sanction authorities of state welfare offices. <br></br>
                      4. The sanctioned scholarship from the welfare office will be credited in the Institute account. <br></br>
                      5. Collecting no dues from the accounts <br></br>
                      6. Taking approval of the Director’s to disbursement of scholarship to the concerned <br></br> <br></br>

                      <b>Parent Departmental/ Private Scholarships </b><br></br>
                      1. Providing bonafide certificate <br></br>
                      2. Collecting undertaking (the Student should not avail any tuition fee waiver/scholarships from any sources) <br></br>
                      3. Forwarding the applications

                    </td>

                  </tr>


                  <tr>
                    <td>
                      <b>Foreign Seat</b><br></br>
                      Phone: +91-870-2462061 <br></br>
                      Email: acad_foreignseat@nitw.ac.in
                    </td>



                    <td>

                      <b>  ICCR</b><br></br>
                      1. Fee structure every year (B.Tech and M.Tech)<br></br>
                      2. Bonafide certificates<br></br>
                      3. Joining reports, Invoice<br></br>
                      4. Performance report<br></br>
                      5 .Extension letters<br></br>
                      6. Utilization certificates<br></br>
                      7. Reimbursement of final Project expenses<br></br>
                      8. Course completion and Flight tickets<br></br><br></br>

                      <b>Study in India (SII)</b><br></br>
                      1. Update of data in the portal every year Providing seat matrix, Fee structure every year<br></br>
                      2. Verifying the assigned student through portal<br></br>
                      3. Sending offer letter to selected students<br></br>
                      4. Sending the joining reports, Invoice to SII<br></br>
                      5. Providing necessary documents and information when every requested by SII<br></br><br></br>

                      <b>SPDC Scholarships</b><br></br>
                      1. Fee structure every year (B.Tech and M.Tech)<br></br>
                      2. Bonafide certificates<br></br>
                      3. Invoice<br></br>
                      4. Performance report<br></br>
                      5. Utilization certificates<br></br><br></br>

                      <b>Nepal Scholarships</b>

                    </td>

                  </tr>










                </tbody>
              </table>












            </div>
          )}


          {/* ...remaining code... */}









        </div>
      </div>
    </div>
  );
};




export default DeanAcademic;